<template>
  <div class="flex items-center w-full">
    <campaign-list-dropdown />
    <div class="flex-none">
      <div
        class="flex items-center w-full "
        :class="{'px-4 pt-2': !showGoBackBtn}"
      >
        <router-link
          v-if="showGoBackBtn"
          class="btn btn-square btn-ghost"
          :to="backPath"
        >
          <div>
            <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg> -->
          </div>
        </router-link>
        <!-- <h1 class="text-2xl">
          <span v-if="currentCampaign">{{ currentCampaign.name || '' }}
          </span>
        </h1> -->
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import CampaignListDropdown from './CamaignListDropdown.vue'
export default {
  components: { CampaignListDropdown },

  props: {
    backPath: {
      type: Object,
      required: true
    },
    showGoBackBtn: {
      type: Boolean,
      required: true
    }
  },
  async mounted () {
  },
  computed: {
    ...mapGetters({
      currentCampaign: 'ANALYTICS/getCurrentCampaign'
    })
  }

}
</script>

<style>
</style>

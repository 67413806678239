<template>
  <div
    class="flex items-center"
  >
    <select
      v-if="campaigns.length > 0"
      class="form-select border px-8 py-4 select-campaign-dropdown text-base mr-12"
      @change="selectCampaign(selectedCamp)"
      v-model="selectedCamp"
    >
      <option
        v-for="campaign in campaigns"
        :key="campaign.id"
        :value="campaign.id"
      >
        <!-- {{ campaign.id }} -->
        {{ campaign.name }}
      </option>
      <option
        v-if="(currentPages[1] > 1)"
        disabled
      >
        {{ currentPages[0] +1 }} / {{ currentPages[1] }}
      </option>
    </select>
    <router-link
      v-else
      class="btn-secondary create-camp-btn"
      :to="{name: 'CreateCampaign'}"
    >
      Create campaign
    </router-link>
    <div
      v-if="currentPages[1] > 1 "
      class="flex justify-center w-full btn-group"
    >
      <button
        v-for="page in currentPages[1]"
        :key="page"
        class="btn btn-outline btn-base"
        :class="{'btn-active': page === currentPages[0] + 1}"
        :to="{page}"
        @click="nextPage(page-1)"
      >
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedCamp: null,
      selectedCampaign: null
    }
  },
  computed: {
    ...mapGetters({ campaigns: 'CAMPAIGNS/getCampaigns', currentPages: 'CAMPAIGNS/getPages' })
  },
  async mounted () {
    if (this.$route.params.campaignId) {
      this.selectedCamp = this.$route.params.campaignId
    } else if (this.$store.state.ANALYTICS.currentCampaign) {
      this.selectedCamp = this.$store.state.ANALYTICS.currentCampaign.id
      this.buildPath(this.$store.state.ANALYTICS.currentCampaign)
    } else {
      this.selectedCamp = this.campaigns[0].id
      this.buildPath(this.campaigns[0])
    }
  },
  methods: {
    async nextPage (page) {
      // update query
      if (!this.$route.params.campaignId) {
        this.$router.push({ name: 'SelectCampaign', params: { page } })
      }
      this.$store.commit('CAMPAIGNS/SET_CURRENT_PAGE', page)
      await this.$store.dispatch('CAMPAIGNS/getOrganisationCampaigns')

      if (this.$route.params.campaignId) {
        this.selectedCamp = this.$route.params.campaignId
      } else if (this.$store.state.ANALYTICS.currentCampaign) {
        this.selectedCamp = this.$store.state.ANALYTICS.currentCampaign.id
        this.buildPath(this.$store.state.ANALYTICS.currentCampaign)
      } else {
        this.selectedCamp = this.campaigns[0].id
        this.buildPath(this.campaigns[0])
      }
    },
    buildPath (campaign) {
      if (campaign.Questionnaires && campaign.Questionnaires.length > 0) {
        this.$router.push({ name: 'Analytics', params: { campaignId: campaign.id, page: this.currentPages[0] } })
      } else {
        this.$router.push({ name: 'Scoreboard', params: { campaignId: campaign.id, page: this.currentPages[0] } })
      }
    },
    selectCampaign (campaignId) {
      this.$store.dispatch('SCOREBOARD/emptyFilter')
      this.selectedCampaign = this.campaigns.filter(x => x.id === campaignId)
      if (this.selectedCampaign.length > 0) {
        this.buildPath(this.selectedCampaign[0])
      }
    }
  }
}
</script>
